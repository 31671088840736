<div id="app"><nav class="ui menu no-print" id="nav-bar" inverted=""><a class="item" @click="toggleSidebar"><i class="bars icon"></i></a><!--router-link.item(to="/", exact="true", :title="$t('home')")i.home.icon(title="$t('home')")
span.fat-only {{ $t('home') }}--><router-link class="item" to="/course" :title="$t('course')"><i class="users icon" :title="$t('course')"></i><span>{{ $t('courses') }}</span></router-link><!-- router-link.item(to="/empower", :title="$t('empower')")i.user.icon(title="$t('empower')")
span {{ $t('empower') }}--><router-link class="item" to="/trace" exact="" name="trace"><i class="sort amount icon"></i><span>{{ $t('trace') }}</span></router-link><router-link class="item fat-only" to="/trace_vue" exact="" name="trace"><i class="sort amount up icon"></i><span>{{ $t('trace_vue') }}</span></router-link><router-link class="item fat-only" to="/comments" :title="$t('comments')"><i class="thumbs up icon"></i><span>{{ $t('clients') }}</span><span>{{ $t('comments') }}</span></router-link><router-link class="item fat-only" to="/sponsor"><i class="gift icon"></i>贊助創作</router-link><!--router-link.item.fat-only(to="/change", :title="$t('change')")i.recycle.icon(:title="$t('change')")
span {{ $t('change') }}--><!--.ui.simple.dropdown.itemimg.flag.fat-only(:src="$t('flag')")
i.angle.down.icon
.menu(button, inverted="true")
  .item(@click="locale = 'zh-TW'") 
    img.flag(src="https://00.ifreesite.com/world-i/taiwan_flag.png", alt="正體中文")
    | 正體中文
  .item(@click="locale = 'en'")
    img.flag(src="https://00.ifreesite.com/world-i/united_states_of_america_flag.png", alt="English")
    | English--><div class="right menu fat-only"><router-link class="item" to="outer"><i class="chess icon"></i><span class="fat-only">{{$t('outer')}}</span></router-link><button class="item" @click="copyLink()"><i class="linkify icon"></i><span class="fat-only">{{ $t('copy link') }}</span></button></div></nav><div class="ui sidebar vertical menu" id="side-menu" :class="{'hidden': !sidebarVisible}"><router-link class="item" to="/"><i class="home icon"></i>{{ $t('home') }}</router-link><router-link class="item" to="/course"><i class="users icon"></i>{{ $t('courses') }}</router-link><router-link class="item" to="/empower"><i class="user icon"></i>{{ $t('empower') }}</router-link><router-link class="item" to="/trace" exact="" name="trace"><i class="sort amount icon"></i><span>{{ $t('trace') }}</span></router-link><router-link class="item" to="/trace_vue" exact="" name="trace"><i class="sort amount up icon"></i><span>{{ $t('trace_vue') }}</span></router-link><router-link class="item" to="/comments"><i class="thumbs up icon"></i>{{ $t('comments') }}</router-link><router-link class="item" to="/change"><i class="recycle icon"></i>{{ $t('change') }}</router-link><router-link class="item" to="/sponsor"><i class="gift icon"> </i>贊助創作</router-link><router-link class="item" to="/outer"><i class="chess icon"></i>{{ $t('outer') }}</router-link></div><div class="ui sidebar bg" :class="{'hidden': !sidebarVisible}" @click="toggleSidebar"></div><div id="main"><router-view my_email="bestian@gmail.com" :likes="likes" :chats="chats" :news="news" :dark="dark" :email="email" :locale="locale" @changeLang="changeLang" :logged="logged"></router-view></div><!--.ui.divideriframe(src="https://docs.google.com/forms/d/e/1FAIpQLSdLWhUpuo_SpWd47VXSWjzFqjanPG2h8lJsO1e0F3N6Ce0njw/viewform?embedded=true" width="100%" height="1068" frameborder="0" marginheight="0" marginwidth="0") 載入中…--></div>