import { createApp } from 'vue';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueGtag from 'vue-gtag'
import { createI18n } from 'vue-i18n'

import zh from './locales/zh-TW.json'
// import cn from './locales/zh-CN.json'
import en from './locales/en.json'
import de from './locales/de.json'
import ja from './locales/ja.json'

import 'semantic-ui-css/semantic.min.css'
import './db'



const i18n = createI18n({
  legacy: false, // 要把 legacy 設為 false，才可以使用 Composition API
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  globalInjection: true,
  messages: {
    'zh-TW': zh,
    // 'zh-CN': cn,
    'en-US': en,
    'en': en,
    'de': de,
    'ja': ja,
  }
})




const app = createApp(App)

app.use(router)
.use(i18n)
.use(VueGtag, {
  config: { id: 'G-NXRRCJB510' },
}, router)
.mount('#app')
